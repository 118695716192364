import React, { Component } from "react"
import { Link } from "gatsby"
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
} from "reactstrap"

import "./header.scss"

class Header extends Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  closeMenu = () => {
    this.setState({
      isOpen: false,
    })
  }

  render() {
    const { siteTitle, logo } = this.props
    return (
      <div className="header">
        <Navbar color="blue" dark expand="md" className="navbar fixed-top">
          <Container>
            <Link to="/#hero" className="navbar-brand" onClick={this.closeMenu}>
              <img
                src={logo}
                alt={`${siteTitle} Logo`}
                height="55px"
                width="55px"
                className="mr-2"
                loading="lazy"
              />
            </Link>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <Link
                    className="nav-link"
                    activeStyle={{ color: '#FFA500' }}
                    to="/#hero"
                    onClick={this.closeMenu}
                  >
                    Início
                  </Link>
                </NavItem>
                <NavItem>
                  <Link 
                    className="nav-link" 
                    activeStyle={{ color: '#FFA500' }}
                    to="/projects"
                    onClick={this.closeMenu}
                  >
                    Projetos
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    className="nav-link"
                    activeStyle={{ color: '#FFA500' }}
                    to="/services"
                    onClick={this.closeMenu}
                  >
                    Produtos
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    className="nav-link"
                    activeStyle={{ color: '#FFA500' }}
                    to="/about"
                    onClick={this.closeMenu}
                  >
                    Sobre nós
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    className="nav-link"
                    activeStyle={{ color: '#FFA500' }}
                    to="/contact"
                    onClick={this.closeMenu}
                  >
                    Contactos
                  </Link>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </div>
    )
  }
}

export default Header
